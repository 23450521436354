import _extends from "/ecomm-marketplace/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';

var AccountIcon = function AccountIcon(props) {
  return __jsx("svg", _extends({
    width: "16",
    height: "9",
    viewBox: "0 0 16 9",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, props), __jsx("path", {
    d: "M14.8986 0.198247C15.1512 -0.0660818 15.5593 -0.0660818 15.8118 0.198247C15.9322 0.323207 16 0.493691 16 0.67159C16 0.849489 15.9322 1.01997 15.8118 1.14493L8.45613 8.80448C8.20365 9.06517 7.79915 9.06517 7.54667 8.80448L0.18912 1.14689C0.0681426 1.02186 9.71764e-08 0.85095 9.93036e-08 0.672567C1.01431e-07 0.494184 0.0681426 0.32328 0.18912 0.198247C0.441603 -0.0624447 0.846101 -0.0624447 1.09858 0.198247L8.00047 7.18299L14.8986 0.198247Z",
    fill: "#393D40"
  }));
};

export default AccountIcon;