import React from 'react';
import _ from 'lodash';
import Imgix from 'shared/components/imgix';
import styled from 'styled-components';
import { Description } from '../../typography';

export default function AboutTheBrand({ brand, isLastSection }) {
  const descriptionParagraphs = _.map(_.split(brand?.description ?? '', '\n'), (line) => (
    <p key={line}>{_.isEmpty(line) ? ' ' : line}</p>
  ));

  return (
    <Container>
      {brand.imageUrl && (
        <Imgix
          sizes='auto'
          width={207}
          htmlAttributes={{ alt: brand.name ? `${brand.name} Logo` : `Logo` }}
          src={brand.imageUrl}
        />
      )}

      {brand.description && (
        <StyledDescription isLastSection={isLastSection}>{descriptionParagraphs}</StyledDescription>
      )}
    </Container>
  );
}

const Container = styled.div`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};

  > img {
    display: block;
    margin-bottom: 21px;
    max-width: 207px;
    object-fit: scale-down;
  }
`;

const StyledDescription = styled(Description)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
  p {
    margin-bottom: ${({ isLastSection }) => (isLastSection ? `0px` : `22px`)};
  }
`;
