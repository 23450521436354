import { ElementType } from 'react';
import { TFunction, TFunctionResult } from 'hooks/use-translation';
import { GqlProducts } from 'types/graphql';
import EffectTiles from '../effects';
import AboutTheBrand from '../brand';
import Terpenes from '../terpenes';
import Cannabinoids from '../cannabinoids';

export type ProductDetailData = {
  product: GqlProducts;
  isMobile: boolean;
};

export type SectionFlags = {
  hasEffects: boolean;
  hideEffects: boolean;
  hasTerpenes: boolean;
  hideTerpenes: boolean;
  hasCannabinoids: boolean;
  hasBrandDescription: boolean;
};

export enum Section {
  effects = 'effects',
  terpenes = 'terpenes',
  cannabinoids = 'cannabinoids',
  brand = 'brand',
}

type ConditionsCheckFn = (flags: SectionFlags) => boolean;
type TranslationFn = (t: TFunction) => TFunctionResult;
type SectionConfig = {
  [key in Section]: { translateTitle: TranslationFn; checkIfVisible: ConditionsCheckFn; component: ElementType };
};

const sectionConfig: SectionConfig = {
  [Section.brand]: {
    translateTitle: (t) => t('product.about-the-brand', 'About the Brand'),
    checkIfVisible: ({ hasBrandDescription }) => hasBrandDescription,
    component: AboutTheBrand,
  },
  [Section.effects]: {
    translateTitle: (t) => t('common.effect_plural', 'Effects'),
    checkIfVisible: ({ hasEffects, hideEffects }) => hasEffects && !hideEffects,
    component: EffectTiles,
  },
  [Section.terpenes]: {
    translateTitle: (t) => t('common.terpene_plural', 'Terpenes'),
    checkIfVisible: ({ hasTerpenes, hideTerpenes }) => hasTerpenes && !hideTerpenes,
    component: Terpenes,
  },
  [Section.cannabinoids]: {
    translateTitle: (t) => t('common.cannabinoid_plural', 'Cannabinoids'),
    checkIfVisible: ({ hasCannabinoids }) => hasCannabinoids,
    component: Cannabinoids,
  },
};

export default sectionConfig;

export function getVisibleSections(flags: SectionFlags): string[] {
  return Object.keys(Section).filter((name: keyof typeof Section) =>
    sectionConfig[Section[name]].checkIfVisible(flags)
  );
}
