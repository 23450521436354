import _ from 'lodash';
export function isDescriptionHtmlBlank(html) {
  // Strip off p and br tags, clean blankspace, and check for empty
  return _.flow([function (str) {
    return _.replace(str, /<\/?p>/g, '');
  }, function (str) {
    return _.replace(str, /<\/?br>/g, '');
  }, _.trim, _.isEmpty])(html);
}
export function getDescriptionHtml(product) {
  var returnValue = '';

  if (product) {
    var description = _.get(product, 'Description', '') || '';
    var descriptionHtml = _.get(product, 'descriptionHtml', '') || '';
    var blankDescriptionHtml = isDescriptionHtmlBlank(descriptionHtml);

    if (blankDescriptionHtml) {
      returnValue = _.join(_.map(description.split('\n'), function (line) {
        return "<p>".concat(_.isEmpty(line) ? ' ' : line, "</p>");
      }), '');
    } else {
      returnValue = descriptionHtml;
    }
  }

  return returnValue;
} // Expects to only work in the browser, where DOMParser is available.

export function getSimpleDescription(product, Parser) {
  if (!Parser) {
    console.log('DOMParser not available in this context. Is this a browser?');
    return '';
  }

  var productDescription = getDescriptionHtml(product);
  var parserInst = new Parser();
  return parserInst.parseFromString(productDescription, 'text/html').documentElement.textContent;
}
export default {
  getSimpleDescription: getSimpleDescription,
  getDescriptionHtml: getDescriptionHtml,
  isDescriptionHtmlBlank: isDescriptionHtmlBlank
};