import React from 'react';
import styled from 'styled-components';

import { Button } from 'components/core';
import useTranslation from 'hooks/use-translation';

import { CartV2 } from 'src/assets/cart-v2';

// TODO: disable button once max quantity/limit is hit ch 47014
export function AddToCartButton({ onClick, disabled = false }) {
  const { t } = useTranslation();
  return (
    <Container>
      <StyledAddToCartButton variant='primary' size='medium' className='icon' onClick={onClick} disabled={disabled}>
        <CartV2 height={20} width={20} />
        <span>{t('common.add-to-cart', 'Add to cart')}</span>
      </StyledAddToCartButton>
    </Container>
  );
}

export function BuyingOptionsButton({ onClick }) {
  const { t } = useTranslation();
  return (
    <BuyingOptionsContainer>
      <Button variant='primary' size='medium' className='icon' onClick={onClick} data-testid='buying-options-modal'>
        <CartV2 height={20} width={20} />
        {t('common.view-buying-options', 'View buying options')}
      </Button>
    </BuyingOptionsContainer>
  );
}

const StyledAddToCartButton = styled(Button)`
  height: 53px;
  width: 166px;
  padding: 0 21px 0 23px !important;
  display: flex;
  align-items: center;
  white-space: nowrap;
  span {
    margin-top: 2px;
  }
`;

const Container = styled.div``;

const BuyingOptionsContainer = styled.div`
  margin-bottom: 40px;
`;
