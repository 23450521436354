import React from 'react';
import styled from 'styled-components';

import useUI from 'src/hooks/use-ui';
import { ShareIcon } from 'shared/assets/share-icon';
import { VisuallyHidden } from 'shared/components/visually-hidden';

type ShareButtonProps = {
  title?: string;
};

export function ShareButton({ title }: ShareButtonProps): JSX.Element | null {
  const { isEmbedded, iframeParentHref } = useUI();

  async function handleShare(): Promise<void> {
    const sourceUrl = isEmbedded && iframeParentHref ? iframeParentHref : window.location.href;
    const currentUrl = typeof window !== 'undefined' ? sourceUrl : '';

    const shareData = {
      title,
      url: currentUrl,
    };

    try {
      await navigator.share(shareData);
    } catch (err) {
      console.error('Error sharing:', err);
    }
  }

  return (
    <ShareButtonContainer>
      <VisuallyHidden>Share {title}</VisuallyHidden>
      <ShareIcon onClick={handleShare} />
    </ShareButtonContainer>
  );
}

const ShareButtonContainer = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  z-index: 1;
`;
