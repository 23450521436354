import React from 'react';
import styled from 'styled-components';

import { mediaQueries } from 'shared/styles';
import { UseProductOptionsStateReturn } from 'src/hooks/use-product-options-state';
import { OptionTile } from 'src/components/option-tile';

type OptionsListV2Props = Pick<UseProductOptionsStateReturn, 'options' | 'selectedOption'> & {
  hideDiscount: boolean;
  onClick: (option: UseProductOptionsStateReturn['selectedOption']) => void;
};

export const OptionsListV2 = ({
  options,
  hideDiscount,
  selectedOption,
  onClick,
}: OptionsListV2Props): JSX.Element | null => {
  const hasMultipleOptions = options.length > 1;

  if (options.length === 0) {
    return null;
  }

  return (
    <Wrapper data-testid='options-list'>
      <Options>
        {options.map((option) => (
          <OptionTile
            key={option.value}
            label={option.label}
            currentPrice={option.special ? option.special.formattedPrice : option.formattedPrice}
            originalPrice={option.special ? option.formattedPrice : null}
            discountLabel={option.special && !hideDiscount ? option.formattedDiscount : null}
            hasMultipleOptions={hasMultipleOptions}
            onClick={() => onClick(option)}
            isSelected={selectedOption.value === option.value}
            isFixedSize
          />
        ))}
      </Options>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin: 16px 0 32px;

  @media ${mediaQueries.belowMedium} {
    overflow-x: auto;
    -ms-overflow-style: none;
    scroll-behavior: smooth;
    scrollbar-width: none;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Options = styled.div`
  display: flex;
  gap: 10px;

  @media ${mediaQueries.medium} {
    flex-wrap: wrap;
  }
`;
