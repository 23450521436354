import React, { SVGAttributes } from 'react';

export function ShareIcon(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.5498 2V13.7M12.5498 2L8.7998 5.75M12.5498 2L16.2998 5.75M7 9.20001H5.5C4.67157 9.20001 4 9.87159 4 10.7V20.3C4 21.1284 4.67157 21.8 5.5 21.8H19.3C20.1284 21.8 20.8 21.1284 20.8 20.3V10.7C20.8 9.87159 20.1284 9.20001 19.3 9.20001H17.8'
        stroke='#485055'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
