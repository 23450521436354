/* eslint-disable react/react-in-jsx-scope */
import EmptyLoadingState from 'components/empty-loading-state';
import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { mediaQueries } from 'shared/styles';
import { useFlags } from 'launchdarkly-react-client-sdk';

import Product from 'components/product';
import BackToMenu from 'components/product/back-to-menu';
import { Container } from 'components/product/layout';
import { CategoryOptions } from 'shared/constants';
import { OftenPurchasedWithCarousel, RelatedItemsCarousel, RecentlyViewedCarousel } from 'src/components/carousels';
import { DispensaryProductLayout } from 'src/components/layouts/dispensary-product-layout';

import useProduct from 'hooks/use-product';
import moment from 'moment';
import { WhoopsContent, WhoopsReason } from 'src/components/whoops';
import { BreadCrumbs } from 'src/components/bread-crumbs';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUI from 'src/hooks/use-ui';
import useUser from 'src/hooks/use-user';
import { isBrowser } from 'utils/misc-utils';
import OutOfStockMessage from '../../components/out-of-stock-message';

export default function DispensaryProductPage() {
  const flags = useFlags();
  const { dispensary } = useDispensary();
  const { isKiosk } = useUI();
  const { isLoggedIn } = useUser();
  const { product, loading, archivedProductData, archivedProductDataLoading } = useProduct();
  const archivedProduct = archivedProductData?.archivedProductLookup[0];
  const personalizationRecentlyViewedEnabled = flags['growth.personalization.recently-viewed-carousel.rollout'];
  const showOutOfStockNotification = _.get(dispensary, 'storeSettings.provideBackInstockEmailNotifications', false);
  const showRecentlyViewedCarousel = !isKiosk && isLoggedIn && personalizationRecentlyViewedEnabled;
  const category = useMemo(() => (product ? _.find(CategoryOptions, { value: product.type }) : {}), [product]);

  const isArchivedProductOlderThanOneYear =
    moment(archivedProduct?.updatedAt).isBefore(moment().subtract(1, 'years')) || false;

  if (!dispensary) {
    return null;
  }

  if (!loading && !product && !archivedProductData?.archivedProductLookup?.length && !archivedProductDataLoading) {
    // we finished loading but still don't have a product
    // effectively a 404
    if (isBrowser()) {
      return <WhoopsContent reason={WhoopsReason.pageNotFound} />;
    }
  }

  if (isBrowser() && archivedProduct && isArchivedProductOlderThanOneYear && showOutOfStockNotification) {
    return <OutOfStockMessage dispensary={dispensary} />;
  }

  return (
    <Container key={product?.id}>
      <PDPBreadCrumsContainer>
        <BreadCrumbsContainer>
          <BackToMenu category={category} />
          <BreadCrumbs includeLeftPadding={false} />
        </BreadCrumbsContainer>
      </PDPBreadCrumsContainer>

      <EmptyLoadingState isLoading={loading || archivedProductDataLoading} page='product'>
        {(product || archivedProduct) && <Product product={product || archivedProduct} dispensary={dispensary} />}
      </EmptyLoadingState>

      <RelatedItemsCarousel productId={product?.id} category={product?.type} />
      {product && <OftenPurchasedWithCarousel productId={product.id} category={product.type} />}
      {showRecentlyViewedCarousel && (
        <RecentlyViewedCarousel dispensaryId={dispensary.id} excludeProductId={product?.id} />
      )}
    </Container>
  );
}

DispensaryProductPage.layout = DispensaryProductLayout;

const PDPBreadCrumsContainer = styled.div`
  margin-bottom: 16px;
`;

const BreadCrumbsContainer = styled.div`
  position: absolute;
  top: 20px;
  left: 0px;
  display: grid;
  grid-template-columns: 72px 1fr;
  justify-items: left;

  @media ${mediaQueries.largePhone} {
    grid-template-columns: 0px 1fr;

    & > button[class*='back-to-menu'] {
      visibility: hidden;
    }
  }
`;
