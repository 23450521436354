import React from 'react';

export function FlameIcon(): JSX.Element {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Flame Icon'>
        <path
          id='Vector'
          d='M11.7915 18.7002C11.7283 18.7248 11.6966 18.7951 11.7177 18.8601C11.7379 18.9252 11.803 18.9639 11.8689 18.9498C13.7076 18.5675 15.1288 17.4486 15.9753 15.7117C17.2444 13.1154 16.9227 9.79834 15.1561 7.26522C15.1227 7.21775 15.0612 7.19754 15.0076 7.216C14.9539 7.23357 14.9153 7.28543 14.9179 7.34432C14.9469 8.08439 14.8388 8.78225 14.598 9.42124C14.38 9.99694 13.8333 10.1657 13.3921 10.0761C12.9491 9.9864 12.5096 9.61902 12.5351 9.00025C12.7197 4.27782 10.256 1.7078 9.49482 1.03278C9.45439 0.996744 9.3955 0.989713 9.34892 1.0152C9.30146 1.03981 9.27245 1.08903 9.27685 1.14353C9.47724 3.34436 7.4856 5.96091 6.28224 7.32422C5.13435 8.62503 4.38726 10.1483 4.12182 11.7294C3.76849 13.8336 4.1895 15.7188 5.31012 17.04C6.19872 18.0886 7.50657 18.768 9.09473 19H9.11231C9.1712 19 9.22217 18.9613 9.23799 18.9033C9.25557 18.84 9.22217 18.7732 9.15889 18.7477C8.31775 18.4129 7.70778 17.6948 7.44409 16.7271C7.14965 15.6407 7.34477 14.4163 7.95299 13.4355C7.96178 13.7413 8.01803 14.0314 8.12175 14.3039C8.25886 14.6642 8.59637 14.767 8.87322 14.7134C9.15009 14.6572 9.42255 14.4278 9.40673 14.0428C9.32587 11.9439 10.3024 10.7267 10.7656 10.2694C10.7656 11.3743 11.7016 12.6163 12.3019 13.2956C12.8601 13.9275 13.2222 14.6658 13.3505 15.4331C13.6072 16.9677 13.0218 18.1894 11.7825 18.7002L11.7915 18.7002Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
